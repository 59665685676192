import React from 'react';

const NotFoundPage = () => (
  <>
    {/* Main */}
    <section className="bg-white mx-auto py-12 lg:pb-20 px-4 sm:px-6 lg:px-8 max-w-lg sm:max-w-2xl md:max-w-4xl lg:max-w-7xl">
     <div className="flex-shrink-0 my-auto py-16 sm:py-32">
        <p className="text-sm font-bold text-olivine-600 uppercase tracking-wide">404 error</p>
        <h1 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">Page not found</h1>
        <p className="mt-2 text-base">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-6">
          <a href="/" className="">
            Go back home<span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </section>
  </>
);

export default NotFoundPage;
